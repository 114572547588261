import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/layout';
import CityHeader from '../components/CityHeader';
import SiteFooter from '../components/SiteFooter';
// import ShareEvent from "@bgea/gatsby-components-common/dist/components/Share.js";
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';

function Page({ pageContext }) {
  const page = pageContext;
  console.log(page)

  return (
    <Layout>
      {/* <ShareEvent items={["Facebook", "Twitter", "URL"]} /> */}
      <div>
        <CityHeader page={page} />
        <div className={page.frontmatter.city}>
          <div className="content event-page">
            <div class="min-title">
              <h1>{page.frontmatter.pageTitle}</h1>
            </div>
            <div className="my-2">
              <a
                href={'/' + page.frontmatter.city + '/events/'}
                className="has-text-weight-semibold is-size-6 link"
              >
                {' '}
                &#60;&#60; More Events
              </a>
              <h4 className="mt-6">
                {page.frontmatter.date2} &#64; {page.frontmatter.start_time}
              </h4>
              <hr />
              <h5>
                <span>Venue:</span>
                <br />
                {page.frontmatter.venue_name}
              </h5>
              <h5>
                <span>Address:</span>
                <br />
                {page.frontmatter.venue_address}
                {page.frontmatter?.venue_address_description && (
                  <div className='pt-2 has-text-weight-bold is-size-6'>{page.frontmatter?.venue_address_description}</div>
                )}
              </h5>
              {page.frontmatter.venue_phone && (
                <h5>
                  <span>Phone:</span>
                  <br />
                  {page.frontmatter.venue_phone}
                </h5>
              )}
              <div>
                <MDXRenderer>{page.body}</MDXRenderer>
                <div className="map-responsive">
                  <iframe
                    src={page.frontmatter.map_link}
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    title={page.frontmatter.pageTitle}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SiteFooter />
    </Layout>
  );
}

export default Page;

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        pageTitle
        ogImage
        metaDesc
      }
      body
    }
  }
`;

export const Head = ({
  data: {
    mdx: {
      frontmatter: { title, ogImage, metaDesc },
    },
  },
}) => {
  return <SEO ogImage={ogImage} title={title} description={metaDesc} />;
};
